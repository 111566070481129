import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";

export default function LabelMyPro() {

    return (
        <>
            <Helmet>
                <title>MyPro Nutritions Portfolio | 3D render and product design for Nutrition Supplements | aBox Agency</title>
                <meta name="title" content="MyPro Nutritions Portfolio | 3D render and product design for Nutrition Supplements | aBox Agency" />
                <meta name="description" content="Explore our collaboration with MyPro Nutritions, a leader in sports nutrition and supplements. Our comprehensive design overhaul included 3D models, renders, infographics, and enhanced product labels, elevating the brand’s appeal to fitness enthusiasts." />
                <meta name="keywords" content="MyPro Nutritions, Fitness Goals, Visual Excellence, Sports Nutrition, Supplements, 3D Modeling, Infographics, eCommerce, Product Graphics, Packaging Design, Brand Collaboration, Digital Agency, Design Solutions, Portfolio Showcase, Fitness Enthusiasts, Brand Appeal, Unique Approach, Design Overhaul, Product Line, Jars, Vitamin Bottles, Sachets, Industry Standards, Design Expertise, Successful Delivery, Health and Fitness, Wellness Industry, Comprehensive Services, Brand Identity, 3D Rendering, Label Design, Packaging Design, Blender, Nutrition Supplements, 3D Visualization, Supplement Packaging, Labeling Solutions, 3D Product Modeling, Blender 3D Design, Creative Packaging, Innovative Labeling, 3D Render Services, Custom Packaging, Blender Rendering, 3D Graphics, Supplement Label Design, Packaging Concepts, Blender Models, Labeling Standards, Packaging Trends, Nutrition Label Design, Supplement Packaging Innovation, Blender 3D Visualization, Nutrition Product Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/mypro/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title mb-0"><span className="mypro_title">My Pro</span> <br /> <span className="text_black">Visual Excellence for <br /> Crafting Fitness Goals!</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/mypro/mypro1.webp"
                        alt="mypro" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">Collaborating with My Pro Sport Nutrition, we executed a comprehensive design overhaul for their diverse fitness product line. Our expertise was utilized in creating intricate 3D models, renders, and infographics, enhancing the product of Sports Nutrition and Supplements labels for jars, vitamin bottles, and sachets. This hefty task required a unique approach, successfully elevating the brand’s appeal to fitness enthusiasts.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Sports Nutrition And Supplements</li>
                                            <li>E-commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro2.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro3.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/mypro/mypro4.webp" className="w-100" alt="mypro" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro5.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro6.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/mypro/mypro7.webp" className="w-100" alt="mypro" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro8.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/mypro/mypro9.webp" className="w-100" alt="mypro" />
                                </div>
                                <div className="mt_30">
                                    <img src="/assets/image/label/mypro/mypro10.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/mypro/mypro11.webp" className="w-100" alt="mypro" />
                        </div>

                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro12.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro13.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                        </div>

                        <div className="mt_30">
                            <img src="/assets/image/label/mypro/mypro14.webp" className="w-100" alt="mypro" />
                        </div>

                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro15.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/mypro/mypro16.webp" className="w-100" alt="mypro" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}