import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioAuroLab() {

    return (
        <>
            <Helmet>
                <title>Aurolab's Digital Presence | Custom Website Development | aBox Agency</title>
                <meta name="title" content="Aurolab's Digital Presence | Custom Website Development | aBox Agency" />
                <meta name="description" content="Explore how aBox elevated Aurolab's online visibility with a custom-designed website that showcases their leading ophthalmic solutions. Our innovative approach simplifies medical information, enhancing user engagement and driving traffic." />
                <meta name="keywords" content="aBox, Aurolab, ophthalmic solutions, eye care products, healthcare website design, web design agency, custom web development, Next.js solutions, UI/UX design, medical web solutions, digital marketing for healthcare, onsite SEO services, digital transformation, user-friendly interface, corporate website design, eye care technology, medical information website, online engagement strategies, responsive web design, ophthalmology industry, healthcare digital solutions, innovative healthcare design, website integration services, content strategy for healthcare, medical SEO, digital presence, user experience enhancement, brand visibility for healthcare, healthcare service offerings, ophthalmic product showcase, web solutions for ophthalmology, engaging healthcare websites, custom design for medical industries" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/aurolab/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_aurolab">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Aurolab</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title aurolab_text mb-0">
                                        Building Awareness for Global <br />
                                        <span className="aurolab_title">
                                            Ophthalmic Solutions
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content aurolab_text mb-3">Aurolab is a leader in ophthalmic solutions. We developed a service-based website designed to enhance awareness of Aurolab’s high-quality eye care products and technologies, effectively showcasing their expertise and extensive service offerings.</p>
                                    <p className="services_content aurolab_text">Our objective was to create an engaging website that communicates Aurolab’s mission. We focused on simplifying complex medical information, ensuring users can easily navigate and understand Aurolab's services. Built with Next.js, the site provides a responsive and dynamic user experience.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://aurolab.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="aurolab_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list aurolab_list">
                                                <li>Web Design</li>
                                                <li>Next.js</li>
                                                <li>Web Development</li>
                                                <li>UI/UX</li>
                                                <li>Content Strategy</li>
                                                <li>Onsite SEO</li>
                                                <li>Integrations</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="aurolab_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list aurolab_list">
                                                <li>Ophthalmic healthcare solutions</li>
                                                <li>Corporate site</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_aurolab_logo">
                                <img src="/assets/image/portfolios/aurolab/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/aurolab/aurolab1.webp" className="w-100" alt="aurolab" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title aurolab_title">Targeting Ophthalmic Sector with custom Design</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our work primarily targets the ophthalmic sector, delivering effective digital solutions for eye care and healthcare services. Using Next.js, we created a clean, professional design with intuitive navigation and engaging visuals that effectively showcase Aurolab's products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/aurolab/aurolab2.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/aurolab/aurolab3.webp"
                                            alt="Aurolab" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/aurolab/aurolab4.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aurolab/aurolab5.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/aurolab/aurolab6.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/aurolab/aurolab7.webp"
                                            alt="Aurolab" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect aurolab_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/aurolab/aurolab8.webp"
                                            alt="Aurolab" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/aurolab/aurolab9.webp"
                                            alt="Aurolab" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title aurolab_title">Enhanced Online Presence and Engagement Results</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Our strategy improved Aurolab's online authority. The new website <a href="https://aurolab.com/" target="_blank" className="official_link omsweets_text">www.aurolab.com</a>, increased traffic and user engagement, solidifying their position in the industry.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/aurolab/aurolab10.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/aurolab/aurolab11.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aurolab/aurolab12.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aurolab/aurolab13.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aurolab/aurolab14.webp"
                                        alt="Aurolab" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title aurolab_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/aurolab/aurolab15.webp" className="w-100" alt="aurolab color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/aurolab/aurolab16.webp" alt="aurolab font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s aurolab_title">AuroLab</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg technosport_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/technosport" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}