import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioBontrue() {

    return (
        <>
            <Helmet>
                <title>Bontrue Home Decor | E-commerce Case Study | aBox Agency</title>
                <meta name="title" content="Bontrue Home Decor | E-commerce Case Study | aBox Agency" />
                <meta name="description" content="Experience Bontrue, an exquisite furniture and home decor e-commerce platform crafted by aBox. Our custom-designed Shopify site features precise UI/UX design, a unique gift registry, and seamless user navigation. Elevate your home shopping experience with Bontrue." />
                <meta name="keywords" content="aBox services, Shopify development, UI/UX design, web design, e-commerce platform, home decor, furniture, Bontrue, user experience, SEO, custom form, web development, onsite SEO, digital marketing, online shopping, interior design, gift registry, brand presence, infographics, user engagement, shopping experience, online store, e-commerce solutions, website design, website development, responsive design, mobile optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/bontrue-furniture/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_bontrue">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Bontrue Furniture</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724909912.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title furniture_text mb-0">
                                        Bontrue Furniture <br />
                                        <span className="furniture_title">
                                            Sophisticated E-Commerce Platform for Home Decor
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content furniture_text mb-3">We're excited to unveil a custom-designed e-commerce website that brings a premium furniture and home decor shopping experience to life. Using Figma for precise design alignment and Shopify for robust development, we ensured every element reflects the brand's high standards.</p>
                                    <p className="services_content furniture_text">Our comprehensive approach included enhancing brand presence, creating compelling infographics, and developing a unique gift registry feature. This allows users to curate personalized wishlists, making gifting seamless and special. Explore the elegant and user-friendly platform at Bontrue.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="#" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="furniture_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list furniture_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Custom Form</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="furniture_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list furniture_list">
                                                <li>Home Decor & furniture</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_furniture_logo">
                                <img src="/assets/image/portfolios/bontrue_furniture/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/bontrue_furniture/furniture1.webp" className="w-100" alt="Furniture" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title furniture_title">Optimizing User Engagement Online</h2>
                                    <p className="services_pera furniture_text font_regular mb-0">
                                        Addressing challenges in user navigation and brand cohesion, we crafted a streamlined Shopify solution that showcases exquisite furniture and decor. Discover the transformation at Bontrue.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture2.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/bontrue_furniture/furniture3.webp"
                                            alt="Furniture" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture4.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture5.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture6.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/bontrue_furniture/furniture7.webp"
                                            alt="Furniture" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/bontrue_furniture/furniture8.webp"
                                            alt="Furniture" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title furniture_title">Fostering a Seamless Shopping Experience</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera furniture_text mb-0">
                                        We prioritized intuitive design, visual appeal, and user-friendly functionality to deliver a captivating and efficient online shopping journey for home decor enthusiasts. Visit the site at <a href="#" className="official_link furniture_text">www.bontrue.in</a>.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture9.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture10.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture11.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture12.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture13.webp"
                                        alt="Furniture" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title furniture_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/bontrue_furniture/furniture14.webp" className="w-100" alt="bontrue furniture color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/bontrue_furniture/furniture15.webp" alt="bontrue furniture font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="furniture_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s furniture_title">AuroLab</h3>
                                    <p className="font_medium services_pera furniture_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg bagavad_gita_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/bhagavad-gita" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}