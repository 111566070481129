import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioMurariSweet() {

    return (
        <>
            <Helmet>
                <title>Custom Shopify Website Development | Murari Sweets Online | aBox Agency</title>
                <meta name="title" content="Custom Shopify Website Development | Murari Sweets Online | aBox Agency" />
                <meta name="description" content="aBox redefined the online presence for a premier Indian sweets brand through innovative UI/UX design, Shopify development, and strategic branding." />
                <meta name="keywords" content="Indian sweets, digital transformation, UI/UX design, Shopify development, website redesign, branding solutions, sweets industry digital, e-commerce website, custom web design, user experience enhancement, digital branding, online presence, market leader sweets, sweet shop website, modern web solutions, digital makeover, innovative website design, sweets brand online, engaging user interface, web development, sweets e-commerce, brand visibility, digital footprint, web design services, UI/UX improvements" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/murari-sweets/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_murari_sweets">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name murari_text2_bg omsweets_text">Murari Sweet</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp"  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name murari_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title murari_sweets_text mb-0">
                                        Redefining the Sweet <br />
                                        <span className="murari_sweets_title">
                                            Digital Experience
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content murari_sweets_text mb-3">aBox revolutionized the online presence for a top Indian sweets brand with a strategic digital makeover. By integrating thorough research, innovative UI/UX design, and robust Shopify development, we created a captivating and intuitive website.</p>
                                    <p className="services_content murari_sweets_text">This comprehensive approach not only addressed previous digital gaps but also positioned the brand as a leader in the sweets industry, elevating their online presence and user engagement.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.murarisweets.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="murari_sweets_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list murari_sweets_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="murari_sweets_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list murari_sweets_list">
                                                <li>Confectionery sweets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_murari_logo">
                                <img src="/assets/image/portfolios/murari_sweet/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/murari_sweet/murarisweet1.webp" className="w-100" alt="Murari Sweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title murari_sweets_title">Innovative Digital Solutions for the Sweets Industry</h2>
                                    <p className="services_pera murari_sweets_text font_regular mb-0">
                                        We undertook detailed research and crafted a bespoke UI/UX design, followed by developing a custom Shopify website. Our solution addressed key challenges and requirements, delivering an engaging and effective digital platform for the sweets industry.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet2.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/murari_sweet/murarisweet3.webp"
                                            alt="Murari Sweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet4.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet5.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet6.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="aumex pb-0 sweet_delect murari_banner_bg mt_20">
                                    <div className="gaps gap_20">
                                        <div>
                                            <img src="/assets/image/portfolios/murari_sweet/murarisweet7.webp"
                                                alt="Murari Sweets" className="img-fluid w-100" />
                                        </div>
                                        <div>
                                            <img src="/assets/image/portfolios/murari_sweet/murarisweet8.webp"
                                                alt="Murari Sweets" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title murari_sweets_title">Enhanced Digital Footprint and Customer Engagement</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera murari_sweets_text mb-0">
                                        The revamped website significantly improved the brand’s online footprint and customer interactions. Our cutting-edge design and strategic enhancements helped the brand stand out, driving higher engagement and strengthening their position in the sweets market. Check it out at <a href="https://www.murarisweets.com/" target="_blank" className="portfolio_a">www.murarisweets.com.</a></p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet9.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet10.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet11.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet12.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet13.webp"
                                        alt="Murari Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title murari_sweets_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/murari_sweet/murarisweet14.webp" className="w-100" alt="Murari Sweets color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/murari_sweet/murarisweet15.webp" alt="Murari Sweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="murari_sweets_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s murari_sweets_title">AuroLab</h3>
                                    <p className="font_medium services_pera murari_sweets_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg singlas_sweet_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/singlas-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}