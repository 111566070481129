import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioSparkFusion() {

    return (
        <>
            <Helmet>
                <title>Spark Fusion E-Commerce Website | Health & Nutrition Supplements | aBox Agency</title>
                <meta name="title" content="Spark Fusion E-Commerce Website | Health & Nutrition Supplements | aBox Agency" />
                <meta name="description" content="Discover Spark Fusion’s cutting-edge Shopify e-commerce website, designed by aBox Agency. Experience sleek design, intuitive navigation, and high-quality visuals including 3D renders and infographics. Elevate your brand and boost sales with our comprehensive web design and development services." />
                <meta name="keywords" content="Spark Fusion, health supplements, nutrition supplements, Shopify e-commerce, custom website design, UI/UX design, web development, 3D product renders, product infographics, e-commerce solutions, fitness supplements, online store design, brand identity, SEO services, website redesign, Shopify development, digital transformation, user-friendly design, high-quality visuals, e-commerce strategy, brand enhancement, online sales growth, web design agency, modern website design, intuitive navigation, product visualization, Shopify experts, web design services, e-commerce optimization, user experience design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/spark-fusion/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_sparkfusion">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Spark Fusion</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title cookies_text mb-0">
                                        Spark Fusion <br />
                                        <span className="sparkfusion_title">
                                            Elevating E-Commerce with <br />
                                            Bespoke Health & Nutrition Design
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content cookies_text mb-3">Spark Fusion, a pioneer in health and nutrition supplements, sought a digital platform that mirrored its innovative spirit. Their previous website fell short in terms of aesthetics and user-friendliness. Recognizing this opportunity, aBox Agency crafted a bespoke Shopify e-commerce store tailored to Spark Fusion's brand essence.</p>
                                    <p className="services_content cookies_text">The new website showcases a sleek, modern design seamlessly integrated with intuitive navigation, ensuring a smooth shopping journey. To captivate customers, we incorporated high-definition product visuals, including 3D renders and informative infographics for their entire range of protein and supplement products. This comprehensive digital solution empowers Spark Fusion to solidify its brand identity and drive significant growth in online sales.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://sparkfusion.in/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="sparkfusion_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list myoa_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="sparkfusion_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list myoa_list">
                                                <li>Fitness and Nutrition Supplements</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_sparkfusion_logo">
                                <img src="/assets/image/portfolios/sparkfusion/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/sparkfusion/sparkfusion1.webp" className="w-100" alt="sparkfusion" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title sparkfusion_title">Empowering Growth Through Enhanced User Experience</h2>
                                    <p className="services_pera cookies_text font_regular mb-0">
                                        Our tailored solution for Spark Fusion not only enhanced the aesthetic appeal of their website but also significantly improved user engagement and conversion rates. The seamless navigation and captivating visuals have positioned Spark Fusion as a leader in the competitive health and nutrition market.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion2.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/sparkfusion/sparkfusion3.webp"
                                            alt="sparkfusion" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion4.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion5.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion6.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion7.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion8.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion9.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect sparkfusion_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/sparkfusion/sparkfusion10.webp"
                                            alt="sparkfusion" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/sparkfusion/sparkfusion11.webp"
                                            alt="sparkfusion" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title sparkfusion_title">Experience Spark Fusion's Enhanced Shopping Platform</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera cookies_text mb-0">
                                        Spark Fusion's revamped website has boosted customer engagement and online sales. Visit <a href="https://sparkfusion.in/" target="_blank" className="official_link omsweets_text">www.sparkfusion.in</a> today to explore the new experience.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion12.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion13.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion14.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion15.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion16.webp"
                                        alt="sparkfusion" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title sparkfusion_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/sparkfusion/sparkfusion17.webp" className="w-100" alt="sparkfusion color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/sparkfusion/sparkfusion18.webp" alt="sparkfusion font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="cookies_text perform_pera">Nurdle here, and let me tell you, Abox is the bee's knees! They took our jumbled ideas for a baby clothes and care website and whipped them into something magical. Had a wonderful experience curating my e-Commerce store,they helped me build a unique and clutter free site with shopify. A set of highly talented and eager to work individuals. Great work.!</p>
                                    <h3 className="title_color padd_s sparkfusion_title">Spark Fusion</h3>
                                    <p className="font_medium services_pera cookies_text mb-0 padd_s">Founder of Spark Fusion</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg furniture_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/bontrue-furniture" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}