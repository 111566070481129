import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioKutum() {

    return (
        <>
            <Helmet>
                <title>Kutum Ayurveda’s Digital Revolution | Success Stories: Cosmetics & Skincare | aBox Agency</title>
                <meta name="title" content="Kutum Ayurveda’s Digital Revolution | Success Stories: Cosmetics & Skincare | aBox Agency" />
                <meta name="description" content="See how aBox and Kutum Ayurveda transformed the beauty industry. Our collaboration resulted in a remarkable online platform that highlights Kutum Ayurveda’s premium Ayurvedic skincare and cosmetics, embodying innovation and natural excellence in e-commerce." />
                <meta name="keywords" content="Kutum Ayurveda, aBox, Shopify, E-commerce, Web Agency, Cosmetics, Skincare, Web Development, Ayurvedic Beauty, Client Success Stories, Collaborations, Valued Partnerships, Natural Beauty Products, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, UI/UX, Development, Visual Identity, Onsite SEO, Graphic Design, Digital Transformation, Online Branding, Web Solutions, E-commerce Integration, Shopify Experts, Website Redesign, User Experience Design, Front-end Development, Back-end Development, Content Management System, Digital Marketing Services" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/kutum-ayurveda/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_kutum">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Kutum Ayurveda</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Kutum Ayurveda <br />
                                        <span className="kutum_title">
                                            Celebrating Natural Beauty in the Digital World
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Kutum Ayurveda, a prestigious brand in the cosmetics and skincare industry, follows the Ayurvedic philosophy of beauty—pure, fresh, seasonal, and natural. To enhance their digital footprint, they partnered with us at aBox for a comprehensive website overhaul. We reimagined and rebuilt their site on Shopify, integrating essential features and e-commerce functionalities. The result? A stunning online presence that mirrors their dedication to natural beauty and excellence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://kutumayurveda.com/" target="_blank" className="d-flex align-items-center link_btn kutum_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="kutum_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Visual Identity</li>
                                                <li>3D render</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="kutum_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Cosmetics and Skincare</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_kutum_logo">
                                <img src="/assets/image/portfolios/kutum/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/kutum/kutum1.webp" className="w-100" alt="Kutum Ayurveda" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title kutum_title">Elevating Kutum Ayurveda’s Online Experience</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our talented team at aBox worked tirelessly to elevate Kutum Ayurveda’s online presence. We crafted a visually captivating and user-friendly Shopify website, ensuring it is both functional and aesthetically pleasing. The new site provides a seamless shopping experience, effectively showcasing their Ayurvedic skincare and cosmetic products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/kutum/kutum2.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/kutum/kutum3.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/kutum/kutum4.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 gaps_sweetly bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/kutum/kutum5.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/kutum/kutum6.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/kutum/kutum7.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect kutum_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/kutum/kutum8.webp"
                                            alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/kutum/kutum9.webp"
                                            alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title kutum_title">Blending Ayurvedic Tradition with Digital Innovation</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Kutum Ayurveda, known for their commitment to natural beauty standards, now has a digital platform that harmonizes traditional Ayurvedic principles with cutting-edge e-commerce technology. Visit <a href="https://kutumayurveda.com/" target="_blank" className="official_link omsweets_text">www.kutumayurveda.com</a> to explore their extensive range of skincare and cosmetic products, reflecting quality and authenticity in every detail.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/kutum/kutum10.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/kutum/kutum11.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/kutum/kutum12.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/kutum/kutum13.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/kutum/kutum14.webp"
                                        alt="Kutum Ayurveda" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title kutum_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/kutum/kutum15.webp" className="w-100" alt="Kutum Ayurveda color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/kutum/kutum16.webp" alt="Kutum Ayurveda font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">aBox reimagined our digital presence with a stunning Shopify website. Their understanding of our Ayurvedic philosophy and commitment to quality is evident in the seamless shopping experience they created. We love our new site!</p>
                                    <h3 className="title_color padd_s kutum_title">Abhishek CEO Founder</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Kutum Ayurveda</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg arospice_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/arospice" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}