import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioMyPro() {

    return (
        <>
            <Helmet>
                <title>MyPro's Fitness Evolution|Nutrition Brand's Shopify Store | aBox Agency</title>
                <meta name="title" content="MyPro's Fitness Evolution|Nutrition Brand's Shopify Store | aBox Agency" />
                <meta name="description" content="Join the fitness revolution with MyPro Sport Nutrition's success story, meticulously crafted by aBox Agency. Explore how our creative solutions transformed MyPro's online presence, from label design to a sleek Shopify-powered website. Witness the rise of MyPro as a premier protein seller, catering to the global fitness community with top-tier supplements, innovative label designs, and 3D product renders." />
                <meta name="keywords" content="MyPro Sport Nutrition, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Protein Supplements, Fitness Enthusiasts, Athletes, Success Stories, Web Design, Creative Solutions, aBox Agency, Sports Nutrition, Supplement Provider, Health and Fitness, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, Label Design, Product Renders, Global Reach, Premier Protein Seller, Innovative Supplements, Fitness Revolution" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/mypro/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_mypro">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">My Pro</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Online Fitness Hub: Crafting a Global Workout <br /> Experience
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">MyPro Sport Nutrition, a top-tier supplement provider, initially sought aBox’s expertise for label design. But there was more to the story. Their website needed a makeover. So, aBox rolled up their sleeves, conducted a comprehensive brand analysis, and identified areas for improvement. The result? A polished, Shopify-powered website, complete with fresh label and product designs and 3D product renders. Now, MyPro stands tall as a leading protein seller, serving fitness enthusiasts and athletes across the globe.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://myprosportnutrition.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Sports nutrition and supplements</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center mypro_banner_bg">
                                <img src="/assets/image/portfolios/mypro/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/mypro/mypro1.webp" className="w-100" alt="mypro" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Making the groundwork for a remarkable brand experience</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our team of experts at MyPro Sport Nutrition poured their expertise into bringing to life the brand's vision, meticulously tailoring each feature to cater to unique fitness aspirations. We've cooked up a user-friendly online shopping journey, boasting an intuitive interface and showcasing a diverse array of premium sports nutrition products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/mypro/mypro2.webp"
                                            alt="mypro" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/mypro/mypro3.webp"
                                            alt="mypro" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/mypro/mypro4.webp"
                                            alt="mypro" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/mypro/mypro5.webp"
                                            alt="mypro" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/mypro/mypro6.webp"
                                    alt="mypro" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect mypro_banner_bg">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/mypro/mypro7.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mypro/mypro8.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mypro/mypro9.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent mt_10">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/mypro/mypro10.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mypro/mypro11.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mypro/mypro12.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Precision in elevating the spirit of the brand</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        MyPro Sport Nutrition pledges a nutritional adventure, blending the classic and contemporary in a harmonious array of flavors. Explore the world of performance and well-being at <a href="https://myprosportnutrition.com/" target="_blank" className="official_link">www.myprosportnutrition.com</a> for a symphony of nourishment that will leave your fitness goals satisfied.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/mypro/mypro13.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/mypro/mypro14.webp"
                                        alt="mypro" className="img-fluid w-100" />
                                    {/* <img src="/assets/image/portfolios/go_grub/gogrub12.webp"
                                        alt="mypro" className="img-fluid w-100" /> */}
                                </div>
                            </div>
                            {/* <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/go_grub/gogrub13.webp"
                                    alt="mypro" className="img-fluid w-100" />
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/mypro/mypro15.webp" className="w-100" alt="mypro color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/mypro/mypro16.webp" alt="mypro font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Working with the tech team has been a game-changer. The transformation of our website has not only enhanced our online presence but also enriched our customer’s shopping experiences. Their understanding of our brand and vision has resulted in a platform that truly embodies MyPro Sport Nutrition’s commitment to fitness and wellness. We are now able to reach a broader audience and provide them with our high-quality, performance-driven products. This remarkable transition wouldn’t have been possible without the team’s expertise and dedication to our project.</p>
                                    <h3 className="title_color padd_s text_shiska">Anant Ajudiya</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of MyPro Nutrition</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg teatox_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/teatox-life" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}