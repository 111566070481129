import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import SiteLink from "../../component/icons/SiteLink";
import LetsTalk from "../../component/LetsTalk";

export default function LabelTeatoxLife() {

    return (
        <>
            <Helmet>
                <title>Teatox Herbal Tea Portfolio | Packaging, 3D, and Infographics Services | aBox Agency</title>
                <meta name="title" content="Teatox Herbal Tea Portfolio | Packaging, 3D, and Infographics Services | aBox Agency" />
                <meta name="description" content="Discover our partnership with Teatox, a global herbal tea brand. Our services included label design, 3D modeling, infographics, and unique packaging solutions, all aimed at enhancing the brand’s appeal and simplifying healthy living." />
                <meta name="keywords" content="Teatox, Wellness, Healthy Living, Herbal Tea, Label Design, 3D Modeling, Infographics, eCommerce, Product Graphics, Packaging Design, aBox Agency, aBox design service, aBox label design, aBox 3D design services, Brand Collaboration, Digital Agency, Design Solutions, Portfolio Showcase, 3D Render Services, Brand Identity, Creative Designs, High-Quality Visuals, Diverse Product Line, Unique Packaging, Cylindrical Boxes, Market Influence, Simplifying Wellness, Labeling, Blender, 3D Visualization, Packaging Innovation, 3D Product Modeling, Blender 3D Design, Creative Packaging, Innovative Labeling, Product Labeling, Custom Packaging, Blender Rendering, 3D Graphics, Product Label Design, Packaging Concepts, Blender Models, 3D Design Techniques, Labeling Standards, Packaging Trends" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/label/teatox-life/" />
            </Helmet>

            <Header />

            <div className="bg-white">
                {/* hero section */}
                <div className="hero_padding section_detail">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-lg-8 col-md-10">
                                <p className="product_name writing_mod teatox_text teatox_text_bg">ECOMMERCE</p>
                                <h1 className="services_title mb-0"><span className="text_gred">Teatox Life</span> <br /> <span className="text_black">Showing Wellness:<br />Simplifying Healthy Living</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product Section */}
                <div className="product_banner">
                    <img src="/assets/image/label/teatoxlife/teatoxlife1.webp"
                        alt="teatox life" className="img-fluid w-100" />
                </div>

                {/* detail section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {/* <div className="vr_border"></div> */}
                            <div className="col-xxl-5 col-xl-6 pad_right">
                                <p className="services_content text_black">We partnered with Teatox, a global herbal tea brand, to revamp their product visuals. Our work included designing labels, creating 3D models, and crafting infographics for their diverse product line. We also designed unique cylindrical boxes to match the brand's identity. Despite the challenges, we successfully delivered high-quality visuals that enhanced the brand's appeal.</p>
                            </div>
                            <div className="col-xxl-6 col-xl-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Services</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Packaging</li>
                                            <li>3D</li>
                                            <li>3D Modeling</li>
                                            <li>Product graphics</li>
                                            <li>Infographics</li>
                                        </ul>
                                    </div>
                                    <div className="ui_list_border"></div>
                                    <div className="col-md-6">
                                        <p className="text_black services_menu">Industries</p>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="services_menu_list ui_service_list">
                                            <li>Herbal and wellness teas</li>
                                            <li>E-Commerce</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* view section */}
                <div className="ui_section">
                    <div className="container">
                        <div className="row gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/teatoxlife/teatoxlife2.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/teatoxlife/teatoxlife3.webp" className="w-100" alt="teatox life" />
                                </div>
                                <div className="mt_30">
                                    <img src="/assets/image/label/teatoxlife/teatoxlife4.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/teatoxlife/teatoxlife5.webp" className="w-100" alt="teatox life" />
                        </div>
                        <div className="row mt_30 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/teatoxlife/teatoxlife6.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/teatoxlife/teatoxlife7.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                        </div>
                        <div className="mt_30">
                            <img src="/assets/image/label/teatoxlife/teatoxlife8.webp" className="w-100" alt="teatox life" />
                        </div>
                        <div className="row mt_30 mb-4 gap-md-0 gap-4">
                            <div className="col-md-6">
                                <div>
                                    <img src="/assets/image/label/teatoxlife/teatoxlife9.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="gap_remove">
                                    <img src="/assets/image/label/teatoxlife/teatoxlife10.webp" className="w-100" alt="teatox life" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LetsTalk />

            <Footer />
        </>
    );
}