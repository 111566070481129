import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioAumex() {

    return (
        <>
            <Helmet>
                <title>Aumex’s Health Care Journey | Success Stories: V wonder wash | aBox Agency</title>
                <meta name="title" content="Aumex’s Health Care Journey | Success Stories: V wonder wash | aBox Agency" />
                <meta name="description" content="aBox and Aumex have collaborated to transform a vision into reality. Discover how our innovative solutions have elevated Aumex’s online presence, promoting a wide range of women’s hygiene care products, including ‘V Wonder Wash’, and showcasing our commitment to superior care for women." />
                <meta name="keywords" content="Aumex, aBox, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Personal Care, Intimate Hygiene, Hygiene Care, Women Intimate Care, Success Stories, Web Design, Shopify Plus, Creative Solutions, aBox Agency, Women’s Health, V Wonder Wash, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/aumex/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_aumex">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Aumex</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1703829075.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        From Dream to Reality: <br /> The Hygiene Revolution
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Manprit, a girl deeply concerned about women’s health care, is aware of the importance of hygiene care. She had a dream of "V Wonder Wash”. She has a burning desire to contribute and make a difference. She’s ready to take action and needs guidance on how to proceed. That’s where we, “aBox”, came in. We heard her, understood her vision, and offered a helping hand. We crafted a Shopify website, designed the product, created labels, rendered them in 3D, and built a brand that resonated with her audience. Today, her dream is a reality; her website is live.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://aumex.in/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Development</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Personal care and intimate hygiene</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg-white">
                                <img src="/assets/image/portfolios/aumex/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/aumex/aumex1.webp" className="w-100" alt="aumex" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting Digital Experiences with Precision and Care</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech team carefully built a vision into reality, tailoring each feature to meet specific needs. We designed a smooth online shopping journey, highlighting a wide range of women’s hygiene care products, including “V Wonder Wash”. This digital platform showcases our commitment to superior care for women
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/aumex/aumex2.webp"
                                            alt="aumex" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/aumex/aumex3.webp"
                                            alt="aumex" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/aumex/aumex4.webp"
                                            alt="aumex" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/aumex/aumex5.webp"
                                            alt="aumex" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/aumex/aumex6.webp"
                                    alt="aumex" className="img-fluid w-100" />
                            </div>
                            <div className="aumex pb-0 sweet_delect aumex_banner_bg">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/aumex/aumex7.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aumex/aumex8.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Innovation at the Intersection of Design and Technology</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        We create a caring journey through women’s hygiene care products, blending tradition with modern technology. This website is a blend of care and sophistication, showing dedication to improving women’s health and wellness.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="sweet_delect p-0 bg_transparent">
                                    <img src="/assets/image/portfolios/aumex/aumex9.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 bg-transparent">
                                    <img src="/assets/image/portfolios/aumex/aumex10.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aumex/aumex11.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aumex/aumex12.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/aumex/aumex13.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                </div>
                                <div className="sweet_delect p-0 bg_transparent">
                                    <img src="/assets/image/portfolios/aumex/aumex14.webp"
                                        alt="aumex" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/aumex/aumex15.webp" className="w-100" alt="aumex color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/aumex/aumex16.webp" alt="aumex font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">A big shoutout to aBox for turning my vision into reality! Their expertise in web development, UI/UX design, and branding brought A V Wonder Wash to life. I am grateful for their dedication and seamless execution. I highly recommend aBox for transforming ideas into an impactful online presence!</p>
                                    <h3 className="title_color padd_s text_shiska">Manpreet</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Aumex</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg greenfay_bg">
                    <div className="container">
                        <div className="text-center">
                            <a href="/portfolio/greenfay" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}