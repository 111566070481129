import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioSavaniJewellery() {

    return (
        <>
            <Helmet>
                <title>Savani Jewellery Redesigning | Luxury Shopify Website | aBox Agency</title>
                <meta name="title" content="Savani Jewellery Redesigning | Luxury Shopify Website | aBox Agency" />
                <meta name="description" content="Discover how aBox transformed Savani Jewellery's online presence with a stunning website redesign. Our user-centric approach enhances customer engagement and simplifies international shopping through advanced Shopify integrations." />
                <meta name="keywords" content="aBox, Savani Jewellery, luxury jewelry brand, e-commerce solutions, website redesign, Shopify integration, UI/UX improvement, diamond jewelry online, fine jewelry manufacturing, responsive web design, digital marketing for jewelry, user-friendly shopping experience, currency conversion for e-commerce, bespoke graphic design, online jewelry shopping, customer engagement strategies, high-end jewelry website, jewelry brand visibility, seamless online shopping, luxury brand identity, enhanced online experience, jewelry collections showcase, jewelry website development, mobile-friendly e-commerce, Shopify e-commerce, elegant jewelry design, user experience enhancement, digital transformation for luxury brands, online retail solutions, international shopping convenience, fine jewelry e-commerce, upscale jewelry marketing, custom website solutions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/savani-jewellery/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_savani">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name savani_text2_bg text-white">Savani Jewellery</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1727240957.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name savani_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title savani_title mb-0">
                                        Redesigning a Luxury Jewelry <br />
                                        <span className="savani_text">
                                            Brand's Online Presence
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content savani_text mb-3">A prestigious diamond jewelry brand sought a comprehensive website revamp to reflect its elegant identity. Our team employed Figma to design a user-centric, visually appealing platform.</p>
                                    <p className="services_content savani_text">Through seamless integrations on Shopify and bespoke graphic design, we created a captivating digital presence that effectively showcases exquisite diamond collections while enhancing customer engagement and satisfaction.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://savanijewellery.com/" target="_blank" className="d-flex align-items-center link_btn savani_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="savani_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list savani_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Web Development</li>
                                                <li>UI/UX Improvement</li>
                                                <li>Onsite SEO</li>
                                                <li>Integration</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border savani_border"></div>
                                        <div className="col-md-6">
                                            <p className="savani_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list savani_list">
                                                <li>Fine Jewelry Manufacturing</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg-white">
                                <img src="/assets/image/portfolios/savani/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/savani/savani1.webp" className="w-100" alt="savani jewellery" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title savani_title">Simplifying Currency Conversion for Global Shoppers</h2>
                                    <p className="services_pera savani_text font_regular mb-0">To facilitate seamless international shopping, we implemented advanced Shopify integrations that enable accurate and real-time currency conversion based on the customer's location. This ensures a transparent and convenient shopping experience for customers worldwide.</p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/savani/savani2.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani3.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani4.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/savani/savani5.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani6.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/savani/savani7.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani8.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title savani_title">Enhancing the Online Shopping Journey</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera savani_text mb-0">
                                        The newly redesigned Savani Jewelry website significantly boosted user engagement and sales. Customers now enjoy a streamlined shopping experience, effortlessly navigating across all devices with ease. Experience the transformation yourself at <a href="https://savanijewellery.com/" target="_blank" className="portfolio_a savani_text">www.savanijewellery.com</a>!</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/savani/savani9.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/savani/savani10.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani11.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani12.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/savani/savani13.webp"
                                        alt="savani jewellery" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title savani_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/savani/savani14.webp" className="w-100" alt="savani jewellery color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/savani/savani15.webp" alt="savani jewellery font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="savani_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s savani_title">AuroLab</h3>
                                    <p className="font_medium services_pera savani_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg anand_sweet_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/anand-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}